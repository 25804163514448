// 店铺分析
import request from '@/utils/request'

// 单店铺  时间段查询
export function getListByDate(data) {
  return request({
    url: "/api/amazon/marketSales/listByDate",
    data,
    method: "post"
  })
}

// 查询指标项目初始化
export function indicatorItemInit() {
  return request({
    url: "/api/amazon/marketSales/itemInit",
    method: "get"
  })
}

// 多店铺, 单项目查询
export function getListByItem(data) {
  return request({
     url: "/api/amazon/marketSales/listByItem",
     data,
     method: "post" 
  })
}